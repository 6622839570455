.Sidebar {
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  padding: 12px 0;
  background: var(--black-5);
  border-right: 1px solid var(--black-10);
  z-index: 9999;
}

.Sidebar__nav {
  display: flex;
  flex-flow: column nowrap;
  position: sticky;
  top: 12px;
}
