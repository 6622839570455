/* Change the blue autofill ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Noway Regular", "Helvetica",
    "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ql-tooltip {
  left: 0 !important;
}

@keyframes jingle {
  10%,
  90% {
    transform: translate3d(1px, 0, 0) rotate(0deg);
  }

  20%,
  80% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(1px, 0, 0) rotate(1deg);
  }

  40%,
  60% {
    transform: translate3d(0, 0, 0) rotate(-1deg);
  }
}
