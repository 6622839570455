.SidebarLink__inner {
  display: block;
  padding: 12px 20px;
  position: relative;
  text-decoration: none;
  font-family: Gotham, Arial, sans-serif;
  font-size: 12px;
  color: var(--brand-black);
}

.SidebarLink svg path {
  fill: var(--black-50);
}
.SidebarLink svg {
  height: 24px;
  width: 24px;
}

.SidebarLink--active .SidebarLink__inner:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background: var(--brand-red);
}

.SidebarLink--active svg path {
  fill: var(--brand-red);
}

.SidebarLink .SidebarLink__inner:hover {
  background-color: var(--black-10);
}

.SidebarLink__tooltip {
  white-space: nowrap;
}
